
.product_list {
    .product {
        margin-bottom: 2em;

        .price {
            color: $isotope_colour;
            font-weight: bold;
        }

        .formbody br {
            display: initial;
        }
    }

    @media (min-width:768px) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1%;

        .product {
            width: 31%;
            margin-left: 1%;
            margin-right: 1%;
        }
    }
}

.mod_iso_cart {
    .col_first {
        // Hide image on mobile
        display: none
    }
}
