
/* !Content: Case-Studies-Filter */
#filter {
    margin-bottom: 40px;
    ul {
        margin: 0;
        padding: 0;
    }
    > span,
    ul,
    li {
        display: inline-block;
    }
    li,
    > span {
        margin-bottom: 10px;
        vertical-align: middle;
    }
    > span {
        margin-right: 20px;
    }
    li {
        margin-right: 5px;
    }
    a {
        background-color: #e5e5e5;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.05));
        background-repeat: repeat-x;
        border-radius: 3px;
        color: #333;
        display: block;
        line-height: normal;
        padding: 6px 10px;
        &:hover,
        &.selected {
            background-color: $isotope_colour;
            color: #fff;
        }
    }
}

.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -ms-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -ms-transition-property: height, width;
    -o-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property:    -moz-transform, opacity;
    -ms-transition-property:     -ms-transform, opacity;
    -o-transition-property:      -o-transform, opacity;
    transition-property:         transform, opacity;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
}





/* !Content: Case Studies */
.portfolio-grid {
    margin-right: -20px;
    padding: 0;
    > .layout_latest {
        float: left;
        margin: 0 20px 20px 0;
        text-align: center;
        &.one-fourth {
            width: 220px;
        }
        &.one-third {
            width: 300px;
        }
        .one-half {
            width: 460px;
        }
    }
}

.project-list .entry a {
    display: block;
    margin-bottom: 0px;
}

#main .project-list {
    .entry {
        border-top: none;
        padding-top: 5px;
        &:hover .entry-meta {
            background-color: #fafafa;
            border-color: $isotope_colour;
        }
    }
    .entry-title {
        color: #333;
        a:hover {
            color: $isotope_colour;
        }
    }
    .entry-meta {
        background-color: #f5f5f5;
        border-bottom: 1px solid #dbdbdb;
        padding: 15px;
        text-align: center;
        p {
            margin: 0;
        }
    }
    .entry-content {
        color: #666;
        margin-bottom: 0;
    }
    img {
        border-bottom: 1px solid #E5E5E5;
    }
}

.project-list .layout_latest,
.entry-image {
    position: relative;
}

.project-list .layout_latest:hover .entry-title {
    color: $isotope_colour;
}

.mod_newsreader {
    padding-top: 5px;
    img {
        border: 1px solid #E5E5E5;
    }
}

/* Case Studies - Home */
.home-case a {
    margin-top: 0;
}
