
/* !Layout: Footer */
#footer {
    margin-top: 40px;
    a > span {
        color: $isotope_colour;
    }
    a:hover,
    a:hover > span {
        color: #e5e5e5;
    }
    h3 {
        border-bottom: 1px solid #4c4c4c;
        color: #e5e5e5;
        margin-bottom: 20px;
        padding-bottom: 10px;
        a {
            color: #e5e5e5;
            &:focus,
            &:hover,
            &:active {
                color: #b2b2b2;
            }
        }
    }
}

#footer-top {
    background-color: #333;
    border-top: 4px solid $isotope_colour;
    color: #999;
    font-size: 12px;
    padding: 35px 0 15px;
}

#footer-bottom {
    background-color: #1a1a1a;
    border-top: 1px solid #000;
    box-shadow: 0 4px 4px rgba(0,0,0,0.2) inset;
    color: #808080;
    font-size: 12px; /* 0.916em = 11px */
    padding: 20px 0 0 0;
    a {
        color: #b2b2b2;
    }
    .copyright {
        margin: 0 -10px 0 0;
        max-width: 100%;
        padding: 0;
        li {
            list-style-type: none;
            padding-right: 5px;
            padding-bottom: 10px;
            vertical-align: middle;
            &.last {
                padding-bottom: 20px;
            }
        }
    }
}





/* !Layout: Footer - Featured */
#footer-featured {
    background: url("../../images/footer-arrow-down.png") no-repeat center top;
    background-color: #f2f2f2;
    color: #333;
    padding: 20px 0 16px;
    h1 {
        margin-top: 20px;
    }
    strong {
        font-weight: 600;
    }
    p {
        font-size: 14px; /* 0.916em = 13px */
        line-height: 1.428em; /* 20px/13px = 1.538em */
        a {
            font-weight: bold;
        }
    }
    a,
    a strong {
        color: $isotope_colour;
    }
    a:hover,
    a:hover strong {
        color: #333;
    }
    .entry-image {
        margin: 0;
    }
    ::-moz-selection {
        background: #333;
    }
    ::selection {
        background: #333;
    }
}





/* !Layout: Scroll to Top */
#toTop,
#toTopHover {
    background: url("../../images/scroll-top.png") no-repeat center center;
    border-radius: 3px;
    bottom: 20px;
    height: 40px;
    overflow: hidden;
    position: fixed;
    right: 20px;
    text-indent: -10000em;
    width: 40px;
}

#toTop {
    background-color: #999;
    display: none;
    &:active,
    &:focus {
        outline: none;
    }
    &:hover {
        background-color: $isotope_colour;
    }
}

#toTopHover {
    display: block;
    opacity: 0;
}


/* !Media: Smartphone-Stylesheet */
@media (max-width:768px) {

    #footer {
        text-align: center;
        a {
            width: auto;
        }
        h3 {
            margin-top: 40px;
        }
        .mod_newslist h3 {
            margin-top: 0;
        }
        .arrow li {
            background-image: none;
        }
    }
}


/* !Media: Tablet- & Desktop-Stylesheet */
@media (min-width:768px) {
    #footer-bottom {
        .social-links {
            text-align: right;
        }
        .copyright {
            li {
                border-right: 1px solid #333;
                display: inline-block;
                margin: 0 5px 20px 0;
                padding-bottom: 0;
                &.last {
                    border-right: none;
                    padding-bottom: 0;
                }
            }
        }
    }

    #footer-featured {
        .circle_image {
            float: right;
        }
    }
}

@media (min-width:980px) {
    #footer-featured {
        .circle_image {
            float: right;
        }
    }
}
