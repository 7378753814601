/* !Media: Print-Stylesheet */
@media print {

    .mod_changelanguage,
    .nav-wrap,
    .content-featured,
    #page-title,
    #footer-top,
    #footer-bottom .copyright li.print,
    .social-links,
    .menu.one-fourth {
        display: none;
    }
    #wrapper {
        border-top: none;
    }
    .boxed #wrapper {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
    }
    .three-fourths {
        width: 100%;
    }
    h2.content-headline {
        margin-top: 40px;
    }
    #footer-bottom .copyright li.first {
        border: none;
    }
    #footer-bottom {
        background-color: #fff;
        border-top: none;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.0) inset;
        color: #666;
    }
}
