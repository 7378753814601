/*
 * Layout:
 * Generic, Containers, Changelanguage, Logo, Search Form, Navigation, Page Title,
 * Breadcrumbs, Menu with Separators, Pagination, Footer, Scroll to Top
 *
 * Content:
 * Headings, Links, Text & Content Blocks, Icon Boxes, Infobox, Dropcaps, Highlights,
 * Preformatted Text & Computer Code, Dividers, Arrow Box Hover, Featured, Social Links,
 * Search Results, Arrow Boxes, Images & Videos, Overlay, Forms, Tables, Pricing Tables,
 * Intro, Buttons, Blockquotes, Testimonial, Lists, Notification Boxes, Accordion, Team,
 * Case-Studies-Filter, Case Studies, Blog, Fundraising, Manual, Circle
 *
 */



/* !Media: Smartphone-Stylesheet */

@import "layout";
@import "elements/download";
@import "sections/containers";
@import "sections/header";
@import "elements/pagination";
@import "sections/footer";


/* !Content: Headings */
h1,
h3,
h4,
h5,
h6 {
	margin-bottom: 15px;
	strong {
		font-weight: normal;
	}
}

h2 strong {
	font-weight: normal;
}

h1 {
	font-size: 28px; /* 2.5em = 30px */
	font-weight: 300;
	letter-spacing: -0.02em;
	line-height: 1.266em; /* 38px/30px */
}

h2,
.mod_newsmenu h5,
.mod_newsarchive h5,
.mod_newsreader h5,
.mod_navigation h5 {
	border-bottom: 1px solid #e5e5e5;
	font-size: 20px; /* 1.666em = 20px */
	font-weight: 600;
	letter-spacing: -0.02em;
	line-height: 1.3em; /* 26px/20px */
	margin-bottom: 25px;
	padding-bottom: 10px;
}

h3 {
	font-size: 16px; /* 1.333em = 16px */
	font-weight: bold;
	line-height: 1.375em; /* 22px/16px */
}

h4 {
	font-size: 15px; /* 1.166em = 14px */
	font-weight: 600;
	line-height: 1.2em; /* 1.285em = 18px/14px */
}

h5 {
	font-size: 14px; /* 1.083em = 13px */
	font-weight: bold;
	line-height: 1.4285em; /* 1.538em = 20px/13px */
}

h6 {
	font-size: 14px; /* 1em = 12px */
	font-weight: bold;
	line-height: 1.5em; /* 18px */
}





/* !Content: Links */
a,
a > * {
	color: $isotope_colour;
	text-decoration: none;
}

a,
a > *,
.button,
.iconbox > a * {
	-webkit-transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease, opacity 0.2s ease-in-out;
	-moz-transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease, opacity 0.2s ease-in-out;
	-ms-transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease, opacity 0.2s ease-in-out;
	-o-transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease, opacity 0.2s ease-in-out;
	transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease, opacity 0.2s ease-in-out;
}

a:focus,
a:hover,
a:focus > *,
a:hover > * {
	color: #333;
}
a > span[itemprop] {
	color: inherit;
}

.extern:after {
	content: " \f08e";
	font-family: FontAwesome;
}

.email:before {
	content: "\f003  ";
	font-family: FontAwesome;
}

.phone:before {
	content: "\f095  ";
	font-family: FontAwesome;
}

.flag:before {
	content: "\f024  ";
	font-family: FontAwesome;
}





/* !Content: Text & Content Blocks */
p {
	margin-bottom: 20px;
}

/*
section,
.mod_article,
#page-header {
	margin-bottom: 40px;
}
*/

#footer .mod_article {
	margin-bottom: 0;
}

.alignleft {
	float: left;
}

img.alignleft {
	margin: 5px 20px 5px 0;
}

.alignright {
	float: right;
}

img.alignright {
	margin: 5px 0 5px 20px;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.aligncenter {
	margin-bottom: 5px;
	margin-top: 5px;
}

.tip, caption, .caption {
	color: #999;
	font-size: 11px; /* 0.916em = 11px */
	font-style: italic;
}

.grey-text {
	color: #999;
}

.invisible {
	display: inline;
	height: 0;
	left: -1000px;
	overflow: hidden;
	position: absolute;
	top: -1000px;
	width: 0;
}

hr {
	background-color: #e5e5e5;
	color: #e5e5e5;
	border: none;
	height: 1px;
	clear: both;
	margin: 40px 0;
}

mark {
	background-color: $isotope_colour;
	font-style: normal;
    font-weight: normal;
}

.note {
	color: #E64141;
}





/* !Content: Icon Boxes */
.start .iconbox > a {
	min-height: 220px;
}
.iconbox {
	margin-bottom: 20px;
	position: relative;
	> a {
		background-color: #f2f2f2;
		border-bottom: 1px solid #d9d9d9;
		display: block;
		min-height: 250px;
		padding: 15px 15px 20px;
		> * {
			color: #333;
		}
		&:focus,
		&:hover {
			background-color: #fafafa;
			border-color: $isotope_colour;
			.iconbox-title {
				color: $isotope_colour;
			}
		}
		> .button {
			bottom: 20px;
			margin-bottom: 0;
			position: absolute;
		}
	}
	p {
		margin-bottom: 10px;
	}
	p:last-child {
		margin-bottom: 0;
	}
	.icon-left > a:hover .iconbox-icon {
		background-color: $isotope_colour;
	}
	.call-to-action {
		color: $isotope_colour;
	}
}

.iconbox-title {
	border: none;
	margin-bottom: 15px;
	padding-bottom: 0;
}

.icon-left .iconbox-icon {
	background-color: #333;
	border-radius: 18px; /* half the width/height */
	float: left;
	height: 36px;
	line-height: 36px; /* same as the height */
	margin-right: 10px;
	text-align: center;
	width: 36px;
	img {
		display: inline-block;
		max-height: 20px;
		max-width: 24px;
	}
}

.icon-top .iconbox-icon {
	margin-bottom: 10px;
	text-align: center;
	img {
		display: inline-block;
		max-width: 100%;
	}
}



@import "elements/infobox";




/* !Content: Dropcaps */
.dropcap {
    float: left;
    font-weight: bold;
    line-height: 1em;
    margin-right: 5px;
    &.simple {
	    font-size: 30px; /* 2.5em = 30px */
	}
	&.with-bg {
	    background-color: #e5e5e5;
	    border-radius: 3px;
	    font-size: 24px; /* 2em = 24px */
	    padding: 6px 10px;
	}
}





/* !Content: Highlights */
.highlight {
	background-color: $isotope_colour;
    color: #fff;
    padding: 2px;
    &.black {
	    background-color: #333;
	}
}





/* !Content: Preformatted Text & Computer Code */
pre,
code {
	background-color: #f2f2f2;
    color: #666;
    font-family: 'Courier New', Courier, monospace;
	font-size: 15px;
}

pre {
    background-color: #fff;
    border-left: 2px solid #dbdbdb;
    margin-bottom: 20px;
    padding: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
}





/* !Content: Dividers */
.divider-double-line {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    height: 1px;
    margin: 40px 0;
}





/* !Content: Arrow Box Hover */
.arrow-box-hover {
	background: url("../images/arrow-box-hover.png") no-repeat left top;
	background-color: $isotope_colour;
	bottom: 0;
	height: 6px;
	left: 50%;
	margin-left: -4px;
	opacity: 0;
	position: absolute;
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
	width: 9px;
}

.iconbox a:focus .arrow-box-hover,
.project-list .layout_latest:focus .arrow-box-hover,
.iconbox a:hover .arrow-box-hover,
.project-list .layout_latest:hover .arrow-box-hover {
	opacity: 1;
}





/* !Content: Featured */
.content-featured {
	background-color: #1a1a1a;
	box-shadow: 0 4px 4px rgba(0,0,0,0.4) inset;
	color: #e5e5e5;
	font-size: 16px; /* 1.333em = 16px */
	line-height: 1.375em; /* 22px/16px */
	margin-top: -40px;
	padding: 30px 0;
	p {
		margin: 0;
	}
	a {
		color: #fff;
		font-weight: bold;
		&:hover {
			color: $isotope_colour;
		}
	}
}




@import "elements/social-links";





@import "elements/search";




/* !Content: Arrow Boxes */
.arrowbox {
	border-bottom: 1px solid #d9d9d9;
	margin-bottom: 20px;
}

.bug .arrowbox {
	height: 190px;
}

.arrowbox-first .arrowbox-title {
	border-radius: 3px 0 0 3px;
}

.arrowbox-title {
	background-color: #1a1a1a;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.04), rgba(0, 0, 0, 0.04));
	background-repeat: repeat-x;
	border: none;
	color: #e5e5e5;
	height: 42px;
	line-height: 42px;
	margin-bottom: 20px;
	padding: 0 15px;
	position: relative;
}

.bug .arrowbox-title {
	opacity: 0.2;
}

.arrowbox-title-arrow-front {
	background: url("../images/arrowbox-title-arrow-front.png") no-repeat left top;
	height: 42px;
	position: absolute;
	right: -10px;
	top: 0;
	width: 10px;
}

.arrowbox-title-arrow-back {
	background: url("../images/arrowbox-title-arrow-back.png") no-repeat left top;
	height: 42px;
	left: -10px;
	position: absolute;
	top: 0;
	width: 10px;
}





/* !Content: Images & Videos */
.inner-caption figure,
figure > a {
	display: block;
	position: relative; /* sets the initial position for the overlay */
}

figure {
	display: inline-block;
	margin-bottom: 15px;
}

.project-list figure {
	margin-bottom: 0;
}

.project-list figure,
.blog-entry-list > .entry > figure,
.mod_newsarchive > .entry > figure,
.mod_newsreader > .entry > figure {
	display: block;
}

figure,
figure img {
	max-width: 100%;
        height: auto;
}

figure img {
	display: block;
}

.alignleft img,
.alignleft.caption {
    margin: 5px 20px 5px 0;
}

.alignright img,
.alignright.caption {
    margin: 5px 0 5px 20px;
}

.aligncenter img,
.aligncenter.caption {
    margin-top: 5px;
    margin-bottom: 5px;
}

.caption .entry-image,
.caption .caption-text {
    margin-bottom: 5px;
}

/* Image with Inner Caption */
.inner-caption figure {
	> .caption {
	    background-color: rgba(0,0,0,0.75);
	    border-top: 1px solid rgba(0,0,0,0.95);
	    bottom: 0;
	    color: #fff;
	    font-style: normal;
	    left: 0;
	    position: absolute;
	    text-shadow: 0 -1px rgba(0, 0, 0, 0.75);
	    width: 100%;
	    z-index: 2;
	    > p {
		    font-weight: bold;
		    padding: 10px;
		    margin: 0;
		    -webkit-transition: padding 0.2s ease-in-out;
		    -moz-transition: padding 0.2s ease-in-out;
		    -ms-transition: padding 0.2s ease-in-out;
		    -o-transition: padding 0.2s ease-in-out;
		    transition: padding 0.2s ease-in-out;
		}

    }
}

.inner-caption.link figure:hover > .caption > p {
    padding-bottom: 15px;
}

.content-gallery {
	ul {
		padding-left: 0;
	}
	li {
		float: left;
		list-style-type: none;
		margin: 0 20px 0 0;
		text-align: center;
		width: 160px;
		&.col_last {
			margin-right: 0;
		}
	}
	img {
		border: 1px solid #E5E5E5;
	}
}
#fotos .content-gallery li {
	width: 170px;
}




/* !Content: Overlay */
.overlay {
	background-color: rgba(255,255,255,0.4);
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

a[data-lightbox] .overlay.zoom {
	background-image: url("../images/overlay-zoom.png");
}

figure a .overlay,
.project-list .layout_latest .overlay,
.lightbox .overlay,
.lightbox-video .overlay,
.link-overlay .overlay {
	filter: alpha(opacity=0);
	opacity: 0;
    z-index: 0; /* for Opera */
}

figure a:hover .overlay,
.project-list .layout_latest:hover .overlay,
.lightbox:hover .overlay,
.lightbox-video:hover .overlay,
.link-overlay:hover .overlay {
	filter: alpha(opacity=100);
	opacity: 1;
    z-index: 1; /* for Opera */
}

figure a .zoom,
.project-list .layout_latest:hover .zoom,
.lightbox .zoom,
.lightbox-video .zoom,
a.image_container:link .zoom,
a.image_container:visited .zoom {
	background-image: none;
}

figure a[data-lightbox]:hover .zoom,
a.image_container:hover .zoom,
a.image_container:active .zoom {
	background-image: url("../images/overlay-zoom.png");
}




@import "forms";

@import "elements/tables";



@import "elements/pricing-box";



/* !Content: Intro */
.buttons-wrap *:last-child {
    margin-right: 0;
}

/* Intro Simple */
.intro {
    margin-bottom: 20px;
    text-align: center;
    strong {
    	color: $isotope_colour;
	    font-weight: 600;
	}
	.buttons-wrap {
	    margin-top: 23px;
	     .button {
		    margin-bottom: 0;
		}
	}
}

/* Intro Box */
.introbox {
	background-color: #f5f5f5;
	border: 1px solid #dbdbdb;
	margin-bottom: 20px;
	padding: 20px;
	position: relative;
	text-align: center;
	strong {
		color: $isotope_colour;
	    font-weight: 600;
	}
	img {
	    left: -4px;
	    position: absolute;
	    top: -4px;
	}
	.buttons-wrap {
	    margin-top: 23px;
	    .button {
		    margin-bottom: 0;
		}
		p {
			font-size: 21px; /* 1.7em */
			line-height: 1.8em;
			margin: -10px 10px 0 10px;
			em {
				color: #333;
				font-style: normal;
				font-weight: bold;
			}
		}
	}
}





/* !Content: Buttons */
.button,
.formbody input.submit,
.formbody button {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    background-repeat: repeat-x;
    border: none;
    border-radius: 3px;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    line-height: normal;
    margin: 15px 10px 20px 0;
    padding: 6px 15px;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.2);
    width: auto;

    /* for hyperlink element with class "button" */
    a {
        color: #fff !important;
    }
}

.button.large {
    font-size: 16px; /* 1.333em = 16px */
    padding: 5px 15px;
}

.button.orange {
    background-color: #f78e0c;
}
.button.yellow {
    background-color: #FBB829;
}
.button.green {
    background-color: #7FAF1B;
}
.button.retro-green {
    background-color: #6d8d5b;
}
.button.teal {
    background-color: #4fa29a;
}
.button.blue {
    background-color: #2A8FBD;
}
.button.indigo {
    background-color: #4c6192;
}
.button.red {
    background-color: #E64141;
}
.button.pink {
    background-color: #bd2346;
}
.button.purple {
    background-color: #723f8e;
}
.button.black {
    background-color: #333;
}

.button:hover,
.formbody input.submit:hover,
.formbody button:hover {
	background-color: #333;
}

.button,
.button.black:hover,
.formbody input.submit,
.formbody button {
	background-color: $isotope_colour;
}





/* !Content: Blockquotes */
blockquote {
    margin-bottom: 20px;
    &.simple {
	    border-left: 2px solid #dbdbdb;
	    padding-left: 10px;
	     .quote-meta {
		    margin-top: 10px;
		}
	}

}

.quote-content {
    color: #666;
    font-style: italic;
    p {
	    margin-bottom: 0;
	    margin-top: 20px;
	}
	> p:first-child {
	    margin: 0;
	}
}





/* !Content: Testimonial */
blockquote.speech-bubble {
    overflow: hidden;
    .quote-content {
	    background: #f5f5f5 url("../images/quote.png") no-repeat 15px 20px;
	    border-bottom: 1px solid #dbdbdb;
	    padding: 20px 15px 20px 45px;
	    position: relative;
	}
	span.quote-arrow {
	    background: url("../images/testimonial-arrow.png") no-repeat left top;
	    bottom: -16px;
	    display: block;
	    height: 16px;
	    position: absolute;
	    right: 52.173%; /* 240px */
	    width: 16px;
	}
	.quote-meta {
	    background: no-repeat left top;
	    float: right;
	    margin-top: 24px;
	    padding-left: 33px;
	    padding-top: 0.166em; /* 2px */
	    text-align: right;
	    &.author-male {
		    background-image: url("../images/testimonial-author-male.png");
		}
		&.author-female {
		    background-image: url("../images/testimonial-author-female.png");
		}
	}
	span {
	    color: #666;
	}
}




@import "elements/lists";
@import "elements/notification-box";
@import "elements/accordion";
@import "elements/team";
@import "elements/case-studies";
@import "elements/blog";
@import "elements/circle";




/* HTML5 Reset CSS Rewriting */
/* Custom text-selection colors (remove any text shadows: twitter.com/miketaylr/status/12228805301) */
::-moz-selection {
	background: $isotope_colour;
}
::selection {
	background: $isotope_colour;
}
ins {
	background-color: $isotope_colour;
}





/* !Media: Tablet- & Desktop-Stylesheet */
@media (min-width:768px) {


	/* !Layout: Navigation */
	#nav-icon {
		display: none; /* hide menu icon initially */
	}
	#nav {
		float: right;
		.level_1,
		.level_1 ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		.level_1 {
			border-bottom: 4px solid transparent;
			float: left;
			ul {
				left: -9999px;
				opacity: 0;
				position: absolute;
				top: 85px;
			}
			li {
				float: left;
				position: relative;
				a {
					color: #333;
					float: left;
					font-size: 14px; /* 1.166em */
					font-weight: 600;
					padding: 41px 20px 26px;
				}
				a.active,
				a.trail,
				a:focus,
				a:hover,
				&:focus a,
				&:hover a {
					color: #fff;
					background: url("../images/nav-arrow.png") no-repeat center bottom;
					outline: none;
				}
				a:focus,
				a:hover,
				&:focus a,
				&:hover a {
					background-color: #333;
				}
				&:focus ul,
				&:hover ul {
					left: 0;
					opacity: 0.99;
				}
			}
		}
		ul.level_1 li {
			a.active,
			a.trail,
			&:hover ul a {
				background-color: $isotope_colour;
			}
		}
	}

	/* !Layout: Navigation: Dropdown */
	#nav {
		.level_1 ul {
			background: #fff;
			background: rgba(255,255,255,0);
			border-top: 7px solid transparent;
			width: 200px;
			z-index: 100;
			li {
				float: none;
				position: static;
			}
		}
		.level_1 li:hover ul a {
			background-image: none;
			border-left: 1px solid rgba(255,255,255,0.1);
			border-right: 1px solid rgba(255,255,255,0.1);
			border-top: 1px solid rgba(255,255,255,0.2);
			color: #fff;
			display: block;
			float: none;
			font-size: 12px;
			font-weight: normal;
			padding: 8px 15px;
			&.active {
				background: #333;
				color: #fff;
				font-size: 12px;
				font-weight: normal;
				padding: 8px 15px;
			}
			&.last {
				border-bottom: 1px solid rgba(255,255,255,0.2);
			}
		}
		.level_1 li:hover ul li a:hover {
			background: #333;
			border-color: #1a1a1a;
		}
		.level_1 li:hover ul li a.active:hover {
			border-color: rgba(255,255,255,0.1);
		}
		.level_1 li:hover ul li a.last.active:hover {
			border-color: rgba(255,255,255,0.2);
		}
		.level_1 li:hover ul li:first-child a {
			border-top: none;
		}
		.level_3 {
			display: none;
		}
	}





	/* !Layout: Page Title */
	#page-title h1 {
		float: left;
		span {
			display: block;
			font-size: 20px;
		}
	}





	/* !Layout: Breadcrumbs */
	#breadcrumbs {
		float: right;
	}





	/* !Content: Tables: Pricing */
	.pricing-table {
		&.one-fourth {
			max-width: 236px;
			width: 25.106%; /* 236px */
		}
		&.one-third {
			max-width: 314px;
			width: 33.404%; /* 314px */
		}
	}





	/* !Columns */
	.one-half,
	.one-third,
	.one-fourth,
	.two-thirds,
	.three-fourths {
		display: inline;
		float: left;
	    margin-right: 2%;
	}

	.one-half {
		width: 49%;
		max-width: 460px;
	}

	.one-third {
		width: 32%;
		max-width: 300px;
	}

	.one-fourth {
		width: 23.5%;
		max-width: 220px;
	}

	.two-thirds {
		width: 66%;
		max-width: 620px;
	}

	.three-fourths {
		width: 74.5%;
		max-width: 700px;
	}

	.column-last {
		margin-right: 0 !important;
	}

    .column-clear {
        clear: left;
    }
}



/* !Media: Tablet-Stylesheet */
@media (min-width:768px) and (max-width:979px) {


	/* !Layout: Navigation */
	#nav .level_1 li a {
		padding: 41px 10px 26px;
	}




	/* !Content: Icon Boxes */
	.box .one-fourth {
		width: 46%;
		max-width: 440px;
	}


}



@import "print";
