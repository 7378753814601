
/* !Content: Tables */
.gen-table,
.gen-table table {
    margin-bottom: 20px;
    width: 100%;
}

.gen-table {
    caption {
        margin-bottom: 10px;
    }
    th {
        font-weight: bold;
    }
    th,
    td {
        background-color: #fff;
        border: 1px solid #dbdbdb;
        padding: 5px;
        text-align: center;
        vertical-align: top;
    }
    .empty-left-top {
        background-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
    }
    .empty-left-bottom {
        background-color: transparent;
        border-left-color: transparent;
        border-bottom-color: transparent;
    }
    td.top-left {
        text-align: left;
        vertical-align: top;
    }
    thead th {
        font-size: 16px; /* 1.333em = 16px */
        line-height: 1.375em; /* 22px/16px */
    }
    thead th,
    td {
        background-color: #f2f2f2;
        border-color: #d9d9d9;
    }
    tbody th,
    tr.odd th,
    tr.odd td,
    tr:nth-child(2n+1) td {
        background-color: #fafafa;
    }
    tfoot td {
        font-size: 11px; /* 0.916em = 11px */
        font-style: italic;
    }
    tfoot .button {
        /* font-size: 1.09em; */ /* 12px/11px */
        font-style: normal;
        margin: 5px 0;
    }
    tfoot + tbody tr.row-last th,
    tfoot + tbody tr.row-last td {
        border-bottom-color: #d9d9d9;
    }
    tbody tr:hover th,
    tbody tr:hover td {
        background-color: #e8f5f7;
    }
}
