
/* !Layout: Download */
.content-download,
.content-downloads {
    ul {
        margin: 0;
        padding-left: 0;
        li {
            list-style-type: none;
            margin-bottom: 8px;
        }
        img {
            vertical-align: top;
        }
    }
}
