/* !Content: Notification Boxes */
.notification-box {
    background: no-repeat 10px center;
    border: 1px solid;
    margin-bottom: 20px;
    padding: 10px 24px 10px 36px;
    position: relative;
    p {
        margin-bottom: 0;
        margin-top: 20px;
    }
    > p:first-child {
        margin: 0;
    }
    p:nth-child(2) {
        margin-top: 0;
    }
}

.notification-box-incomplete {
    background-color: #faede6;
    background-image: url("../../images/roadworks.png");
    border-color: #f5ccb8;
}

.notification-box-new {
    background-color: #c7f7b4;
    background-image: url("../../images/document-new.png");
    border-color: #14a002;
}

.notification-box-info {
    background-color: #d7ebf5;
    background-image: url("../../images/info-16.png");
    border-color: #99c7de;
}

.notification-box-success {
    background-color: #e1ebc5;
    background-image: url("../../images/tick-16.png");
    border-color: #bfd188;
}

.notification-box-warning {
    background-color: #fcf3d7;
    background-image: url("../../images/warning-16.png");
    border-color: #f5d77d;
}

.notification-box-error {
    background-color: #faede6;
    background-image: url("../../images/block-16.png");
    border-color: #f5ccb8;
}
.content-download.notification-box {
    background-image: none;
    padding-left: 20px;
}
.content-download img {
    vertical-align: middle;
}
.notification-box.news-letter {
    padding: 10px;
    text-align: center;
}
.notification-box-info.news-letter {
    background-image: none;
}
.news-letter {
    p {
        margin: 0;
    }
}
.checkbox_container span {
    margin-right: 10px;
}
