
/* !Content: Search Results */
.mod_search {
    .header {
        margin-bottom: 30px;
    }
    .even,
    .odd {
        border-bottom: 1px solid #e5e5e5;
        padding: 20px 0;
        h3 {
            margin-bottom: 12px;
            .relevance {
                color: #ccc;
                font-weight: normal;
            }
        }
        .context {
            margin-bottom: 12px;
        }
        .highlight {
            color: #fff;
            padding: 2px;
        }
        .url {
            margin: 0;
        }
        .filesize {
            color: #666;
        }
    }
    .last {
        border-bottom: none;
    }

}
