
html {
    scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

body {
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px; /* 12px */
    line-height: 1.5em; /* 18px */
    -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
    -webkit-text-size-adjust: 100%;
    &.wide {
        background-color: #fff;
        background-image: none;
        background-position: left top;
        background-repeat: repeat;
    }
    &.boxed {
        background-color: #333;
        background-image: url("../images/background.png");
        background-position: left top;
        background-repeat: repeat;
    }
}

iframe,
video,
audio {
    display: block;
}

embed,
object,
video {
    max-width: 100%; /* Note: keeping IMG here will cause problems if you're using foreground images as sprites. */
}

video {
    height: auto;
    width: 100%;
}

.float_right {
    figure {
        float: right;
        margin-left: 40px;
    }
}
.float_left {
    float: left;
}
.float_right {
    float: right;
}


/* Clearfix */
.clear {
    clear: both;
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
}
