
/* !Content: Social Links */
.social-links {
    margin: 0 -10px 0 0;
    max-width: 100%;
    padding: 0;
    li {
        display: inline-block;
        margin: 0 10px 20px 0;
        vertical-align: middle;
    }
    a {
        display: block;
        font-family: FontAwesome;
        font-size: 22px; /* 2em */
    }
    .twitter a:before {
        content: "\f099";
    }
    .facebook a:before {
        content: "\f09a";
    }
    .gplus a:before {
        content: "\f0d5";
    }
    .linkedin a:before {
        content: "\f0e1";
    }
    .vimeo a:before {
        content: "\f194";
    }
    .youtube a:before {
        content: "\f167";
    }
    .skype a:before {
        content: "\f17e";
    }
    .mail a:before {
        content: "\f003";
    }
    .home a:before {
        content: "\f015";
    }
    .legal a:before {
        content: "\f0e3";
    }
    .github a:before {
        content: "\f09b";
    }
}
