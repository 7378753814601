
/* !Content: Pricing Tables */
.pricing-box {
    margin-bottom: 20px;
    text-align: center;
    &.level-0 {
        opacity: 0.5;
        filter: alpha(opacity=50);
    }
    .price,
    .footer,
    .suitability, {
        background-color: #f2f2f2;
        border: 1px solid #d9d9d9;
    }
    .footer,
    .suitability {
        padding: 10px 15px;
    }
    .suitability {
        height: 100px;
    }
    .footer {
        border-top: none;
    }
    ul.features {
        background-color: #fff;
        border: 1px solid #e5e5e5;
        border-bottom: none;
        border-top: none;
        list-style: none;
        margin: 0;
        padding: 10px 15px;
        li {
            border-top: 1px solid #e5e5e5;
            padding: 10px 0;
        }
        li:first-child {
            border: none;
        }
    }
    .button {
        margin: 5px 0;
    }
    .title {
        background-color: #d9d9d9;
        border: 1px solid #c4c4c4;
        font-size: 22px; /* 1.5em = 18px */
        font-weight: bold;
        line-height: 1em; /* 1.333em = 24px/18px */
        margin: 0;
        height: 35px;
        padding: 15px;
        em {
            font-style: normal;
            font-weight: normal;
        }
        .small {
            font-size: 10px;
            line-height: 1em;
        }
    }
    .price {
        border-top: none;
        line-height: normal;
        margin: 0;
        padding: 10px 15px;
        span {
            color: $isotope_colour;
            font-size: 30px; /* 2.5em = 30px */
            font-weight: bold;
        }
        span.price_month {
            color: #333;
            font-size: 14px;
        }
        span.month {
            color: #333;
            font-size: 10px;
            font-weight: normal;
        }
        sup {
            font-size: 100%;
        }
    }
    &.featured {
        box-shadow: 0 0 8px rgba(0,0,0,0.08);
        .title {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
            background-repeat: repeat-x;
            border-bottom-color: rgba(0,0,0,0.15) !important;
        }
        .title,
        .price span,
        .price sup {
            color: #fff;
        }
        .price,
        .title  {
            background-color: $isotope_colour;
            border-color: $isotope_colour;
        }
    }
}

.pricing-table {
    margin-left: -1px;
    margin-right: 0 !important;
    &.featured {
        position: relative;
        z-index: 1;
    }
    &.one-fourth {
        /* max-width: 236px; */
        width: 100%; /* 236px */
    }
    &.one-third {
        /* max-width: 314px; */
        width: 100%; /* 314px */
    }
}


/* !Media: Smartphone-Stylesheet */
@media (max-width:768px) {

    .pricing-box.level-0,
    .pricing-box .empty {
        display: none;
    }
    .pricing-box {
        ul.features {
            li.empty_first {
                border: none;
            }
        }
    }

}
