
/* !Content: Circle */
.member_circle {
    margin-bottom: 30px;
    padding: 10px;
    &.even {
        background-color: #fcfcfc;
        &.detail {
            background-color: transparent;
        }
    }
}
.level_1 blockquote.speech-bubble {
    border-top: 4px solid #0098ac; //#fb8482; /* #FC9D9B */
}
.pricing-box .level_1 {
    background-color: #FC9D9B;
}
.level_2 blockquote.speech-bubble {
    border-top: 4px solid #f7b688; /* #FAC7A0 */
}
.pricing-box .level_2 {
    background-color: #FAC7A0;
}
.level_3 blockquote.speech-bubble {
    border-top: 4px solid #a3b692; /* #B8C8AA */
}
.pricing-box .level_3 {
    background-color: #B8C8AA;
}
.pricing-box .level_circle {
    background-color: #0098ac;
    color: #fff;
}
#circle_directory {
    position: relative;
}
#circle_level {
    color: #fff;
    position: absolute;
    top: 0;
    right: 10px;
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        display: inline;
        list-style-type: none;
        margin: 0;
        padding: 5px 16px;
        &.level_1 {
            background-color: #fb8482;
        }
        &.level_2 {
            background-color: #f7b688;
        }
        &.level_3 {
            background-color: #a3b692;
        }
    }
}

.pricing-box .features p,
.pricing-box .suitability p {
    margin: 0 auto;
    width: 250px;
}

.member_avatar {
    margin-bottom: 10px;
}
.member_address,
.speech-bubble {
    margin-bottom: 20px;
}

.member_phone {
    margin: 5px 0;
    span.phone:before {
        content: "\f095  ";
        font-family: FontAwesome;
    }
}
.member_city span {
    text-transform: uppercase;
}
.member_description {
    clear: both;
}
.member_circle_3 {
    border-top: 1px solid #F5F5F5;
    padding-top: 30px;
}
.member_website a.home:before {
    content: "\f015  ";
    font-family: FontAwesome;
}
a.hyperlink_txt,
a.hyperlink_txt:focus,
a.hyperlink_txt:hover {
    color: #fff;
}
.mod_iso_circle_directory h3 {
    letter-spacing: 2px;
    margin-top: 0;
    width: 100px;
    &.button:hover {
        background-color: $isotope_colour;
        cursor: text;
    }
}
#level-1,
#level-2 {
    margin-top: 60px;
}
.member_circle_2,
.member_circle_1 {
    &.odd .one-half {
        margin-right: 0;
    }
    .member_avatar {
        border-top: 1px solid #F5F5F5;
        min-height: 110px;
        padding-top: 30px;
    }
}
.member_circle_1 {
    .member_company {
        border-top: 1px solid #F5F5F5;
        padding-top: 30px;
    }
}
.mod_article textarea {
    border: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: 0 0 5px rgba(0,0,0,0.1) inset;
    padding: 6px 3px 6px 10px;
    width: 95%;
    &:hover,
    &:focus {
        background: #f2f2f2;
        border-color: #ccc;
    }
}

.migration {
    &.gen-table th,
    &.gen-table td {
        text-align: left;
    }
    .button {
        margin-top: 0;
        width: 90px;
    }
}
