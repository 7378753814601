

/* !Layout: Changelanguage */
.mod_changelanguage {
    left: 0;
    position: absolute;
    top: 20px;
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style-type: none;
            a {
                background: #333;
                color: #fff;
                display: block;
                padding: 7px;

                > span {
                    color: #fff;
                }
            }
        }
    }
}

/* !Layout: Logo */
#logo {
    float: left;
    font: bold 2.833em/1em 'Open Sans', sans-serif; /* 34px */
    letter-spacing: -0.02em;
    margin-bottom: 0;
    margin-top: 17px;
    max-width: 100%;
    a {
        color: #333;
        display: block;
        > * {
            color: #333;
        }
        img {
            max-width: 100%;
            display: block;
        }
    }
}


/* !Layout: Search Form */
#search-form {
    float: right;
    margin-left: 20px;
    margin-top: 36px;
    position: relative;
}

#search-submit {
    background: url("../../images/search.png") no-repeat left top;
    border: none;
    cursor: pointer;
    height: 30px;
    text-indent: -10000em;
    width: 30px;
}

#s {
    border-radius: 0;
    border: 1px solid #d9d9d9;
    box-shadow: 0 0 5px rgba(0,0,0,0.1) inset;
    position: absolute;
    padding: 6px 10px;
    right: 0;
    top: -33px;
    width: 198px;
    z-index: 1000;
}

#s:hover,
#s:focus {
    border-color: #ccc;
}




/* !Layout: Page Title */
#page-title {
    background-color: #333;
    box-shadow: 0 4px 4px rgba(0,0,0,0.4) inset;
    color: #e5e5e5;
    padding: 26px 0;
    h1 {
        color: #fff;
        margin: 0;
    }
}





/* !Layout: Breadcrumbs */
#breadcrumbs {
    float: left;
    margin-top: 10px;
    text-align: right;
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        display: inline;
    }
    a {
        color: #fff;
        font-weight: bold;
        margin: 0 3px;
        &:hover {
            color: $isotope_colour;
        }
    }
    li:last-child {
        margin-left: 3px;
    }
}
.start #breadcrumbs {
    display: none;
}
#container {
    margin-top: 40px;
}
.start {
    #container {
        margin-top: 0;
    }
}




/* !Layout: Menu with Separators */
.menu ul {
    list-style: none;
    margin: 0 0 40px 0;
    padding: 0;
    li {
        border-bottom: 1px solid #e5e5e5;
        > a,
        > strong {
            background: url("../../images/list-arrow.png") no-repeat 5px 1em; /* 14px */
            display: block;
            padding: 8px 0 8px 16px;
            font-weight: normal;
        }
        > a,
        > a > * {
            color: #333;
        }
        > a:hover {
            background-color: #f2f2f2;
            color: $isotope_colour;
        }
        strong,
        > a.trail {
            background-color: transparent;
            color: $isotope_colour;
            cursor: default;
            &.member {
                color: #333;
            }
        }
    }
    ul {
        border-top: 1px solid #e5e5e5;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            border: none;
            margin: 0 0 0 15px;
        }
        a:hover {
            background-color: transparent;
        }
    }
}



/* !Media: Smartphone-Stylesheet */
@media (max-width:768px) {
    #breadcrumbs {
        display: none;
    }

    /* !Layout: Changelanguage */
    .mod_changelanguage {
        left: 5px;
        top: 10px;
        ul {
            li {
                a {
                    background: transparent;
                    /* font-size: 2.4em; */
                    padding: 3px 5px;
                    &:hover {
                        color: #0C4A53;
                    }
                }
            }
        }
    }

    /* !Layout: Logo */
    #logo {
        margin-bottom: 10px;
    }

    /* !Layout: Navigation */
    #nav .level_1 {
        background: #333;
        box-shadow: 0 4px 4px rgba(0,0,0,0.4) inset;
        /* font-size: 1.5em; */
        margin: 0;
        width: 100%;
        li {
            ul {
                padding: 0;
                li {
                    /* font-size: 0.8em; */
                    a {
                        background: #333;
                        border-left: 5px solid rgba(255,255,255,0.2);
                        &:hover {
                            background: $isotope_colour;
                        }
                    }
                }
            }
        }
        a {
            background: #fff;
            border-bottom: 1px solid rgba(0,0,0,0.2);
            border-top: 1px solid rgba(255,255,255,0.2);
            display: block;
            padding: 12px;
            &.active,
            &.trail {
                background: $isotope_colour;
                color: #fff;
                &:hover {
                    background: $isotope_colour;
                }
            }
            &:hover {
                background: #333;
                color: #fff;
            }
        }
    }

    /* Javascript */
    .js {
        #nav .level_1 {
            position: relative;
        }
        #nav-icon {
            color: #fff;
            cursor: pointer;
            display: block;
            font-size: 28px; /* 2.4em */
            padding: 3px 5px;
            position: absolute;
            right: 5px;
            text-align: right;
            top: 10px;
            width: 45%;
            &:before {
                content: "\f03a";
                font-family: FontAwesome;
            }
            &:hover {
                color: #0C4A53;
            }
            &.active {
                color: #3D6E75;
            }
        }
        #nav .level_1 {
            clear: both;
            display: none;
            left: 0;
            padding: 10px 0;
            position: absolute;
            top: 40px;
            z-index: 10000;
            li {
                clear: both;
                float: none;
                ul {
                    padding: 0;
                }
            }
        }
    }

    /* No Javascript */
    .no-js {
        #nav {
            margin: 100px -3% 0 -3%;
            .level_1 {
                padding: 20px 0;
                ul {
                    padding: 0;
                }
            }
        }
    }

    /* !Layout: Subnavigation */
    .mod_navigation.one-fourth {
        display: none;
    }
}
