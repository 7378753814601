
/* !Content: Forms */
:not(.checkbox_container, .radio_container) > legend {
    color: #0098AC;
    padding-right: 10px;
}
fieldset:not([data-cff-condition], .checkbox_container, .radio_container) {
    border-top: 1px dotted #e5e5e5;
    margin-bottom: 20px;
    fieldset {
        border-top: none;
        legend {
            background-color: transparent;
            color: #333;
            font-weight: bold;
            margin-top: 10px;
            padding-right: 0;
        }
    }
    &#ctrl_notify {
        border: none;
    }
    &#ctrl_newsletter span {
        padding-right: 20px;
    }

    .widget + & {
        margin-top: 20px;
    }

    .one-half + & {
        clear: both;
        float: left;
        width: 100%;
    }

    legend:first-child + .widget-explanation {
        margin-top: 10px;
    }
}
.checkbox_container {
    width: 97%;
}
input,
textarea,
select,
button {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input,
textarea,
button {
    -webkit-appearance: none; /* Fix for Mobile Safari */
}

input[type="checkbox"] {
    -webkit-appearance: checkbox;  /* Fix for Mobile Safari */
}

input[type="radio"] {
    -webkit-appearance: radio; /* Fix for Mobile Safari */
}

input,
button {
    line-height: normal;
}

textarea {
    overflow: auto; /* removes scrollbar in IE */
}

input[type="submit"],
button {
    width: auto;
}

::-webkit-input-placeholder {
    color: #999; /* Chrome, Safari */
}

:-moz-placeholder {
    color: #999;
}

::-moz-focus-inner {
    border: none; /* removes the extra padding and dotted border on buttons in Firefox */
    padding: 0;
}

.mod_personalData .formbody {
    img,
    #ctrl_avatar,
    br {
        display: block;
        margin-bottom: 5px;
    }
    img,
    #ctrl_avatar {
        margin-bottom: 10px;
    }
}

.formbody {
    p {
        margin-bottom: 10px;
    }
    input,
    textarea,
    select {
        border: 1px solid #d9d9d9;
        border-radius: 0;
        box-shadow: 0 0 5px rgba(0,0,0,0.1) inset;
        padding: 6px 3px 6px 10px;
        &:hover,
        &:focus {
            background: #f2f2f2;
            border-color: #ccc;
        }
    }
    .qq-upload-button-selector {
        background: #4fa29a;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        box-shadow: 0 0 5px rgba(0,0,0,0.1) inset;
        color: #fff;
        cursor: pointer;
        padding: 6px 15px;
        text-shadow: 0 -1px rgba(0, 0, 0, 0.2);
        width: 70%;

        input {
            width: 100% !important;
        }
    }
    #ctrl_iso_circle_list_display {
        margin-top: 5px
    }
    #opt_iso_circle_list_display_0 {
        margin-top: 10px;
    }
    input,
    textarea {
        width: 70%;
    }
    label,
    .checkbox_container > legend,
    .radio_container > legend {
        margin-top: 10px;
    }
    br {
        display: none;
    }
    span.mandatory {
        color: $isotope_colour;
        padding-left: 5px;
    }
    .checkbox_container > legend,
    .radio_container > legend,
    label,
    input,
    textarea,
    select {
        margin-bottom: 5px;
    }
    .checkbox_container > legend,
    .radio_container > legend,
    label {
        clear: both;
        display: block;
        font-weight: bold;
        span.captcha_text {
            display: inline;
        }
    }
    .error,
    .confirm {
        color: #e64141;
    }
    label.confirm {
        color: #333;
    }
    select {
        width: 50%;
    }
    textarea {
        height: 160px;
    }
    .checkbox_container,
    .radio_container {
        margin-top: 10px;
        input {
            width: auto;
        }
        label {
            display: inline;
            font-weight: normal;
        }
    }

    .radio_container > span {
        display: block;
    }
}

.captcha_text {
    display: block;
}

.mod_registration {
    margin-top: 40px;
}

p.error,
p.confirm {
    color: #e64141;
}

.ce_form .form-confirmation {
    padding: 20px 20px 0;
    background: rgba(0, 152, 172, 0.2);
    border: 2px solid #0098AC;
}
