
/* !Layout: Pagination */
.pagination {
    border-top: 1px solid #e5e5e5;
    margin: 40px 0;
    padding-top: 40px;
    > p {
        margin-right: 20px;
    }
    ul {
        margin: 0;
        padding: 0;
    }
    ul,
    li,
    > p {
        display: inline-block;
    }
    li,
    > p {
        margin-bottom: 10px;
        vertical-align: middle;
    }
    li {
        margin-right: 5px;
        line-height: normal;
    }
    a {
        background-color: #e5e5e5;
        color: #333;
        display: block;
    }
    a,
    strong.active {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.05));
        background-repeat: repeat-x;
        border-radius: 3px;
        padding: 5px 10px;
    }
    li a:hover {
        background: #333333;
    }
    a:hover,
    strong.active {
        background-color: $isotope_colour;
        color: #fff;
    }
}
