
/* !Content: Lists */
.arrow ul,
.circle ul,
.square ul,
.check ul,
.decimal ol,
.upper-roman ol,
.lower-alpha ol,
.upper-alpha ol,
ul.arrow,
ul.circle,
ul.square,
ul.check,
ol.decimal,
ol.upper-roman,
ol.lower-alpha,
ol.upper-alpha {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
}

.arrow ul,
.circle ul,
.square ul,
.check ul,
ul.arrow,
ul.circle,
ul.square,
ul.check {
    overflow: hidden;
}

.arrow ul ul,
.circle ul ul,
.square ul ul,
.check ul ul,
.decimal ol ul,
.upper-roman ol ul,
.lower-alpha ol ul,
.upper-alpha ol ul,
ul.arrow ul,
ul.circle ul,
ul.square ul,
ul.check ul,
ol.decimal ul,
ol.upper-roman ul,
ol.lower-alpha ul,
ol.upper-alpha ul {
    list-style: none;
    margin: 0 0 0 14px;
    padding: 0;
}

.arrow ul ol,
.circle ul ol,
.square ul ol,
.check ul ol,
.decimal ol,
.decimal ol ol,
.upper-roman ol,
.upper-roman ol ol,
.lower-alpha ol,
.lower-alpha ol ol,
.upper-alpha ol,
.upper-alpha ol ol,
ul.arrow ol,
ul.circle ol,
ul.square ol,
ul.check ol,
ol.decimal,
ol.decimal ol,
ol.upper-roman,
ol.upper-roman ol,
ol.lower-alpha,
ol.lower-alpha ol,
ol.upper-alpha,
ol.upper-alpha ol {
    margin-left: 18px;
}

.arrow ul li,
.circle ul li,
.square ul li,
.check ul li,
.decimal ol li,
.upper-roman ol li,
.lower-alpha ol li,
.upper-alpha ol li,
ul.arrow li,
ul.circle li,
ul.square li,
ul.check li,
ol.decimal li,
ol.upper-roman li,
ol.lower-alpha li,
ol.upper-alpha li {
    margin-bottom: 5px;
}

.arrow ul ul li:first-child,
.arrow ul ol li:first-child,
.circle ul ul li:first-child,
.circle ul ol li:first-child,
.square ul ul li:first-child,
.square ul ol li:first-child,
.check ul ul li:first-child,
.check ul ol li:first-child,
.decimal ol ol li:first-child,
.decimal ol ul li:first-child,
.upper-roman ol ol li:first-child,
.upper-roman ol ul li:first-child,
.lower-alpha ol ol li:first-child,
.lower-alpha ol ul li:first-child,
.upper-alpha ol ol li:first-child,
.upper-alpha ol ul li:first-child,
ul.arrow ul li:first-child,
ul.arrow ol li:first-child,
ul.circle ul li:first-child,
ul.circle ol li:first-child,
ul.square ul li:first-child,
ul.square ol li:first-child,
ul.check ul li:first-child,
ul.check ol li:first-child,
ol.decimal ol li:first-child,
ol.decimal ul li:first-child,
ol.upper-roman ol li:first-child,
ol.upper-roman ul li:first-child,
ol.lower-alpha ol li:first-child,
ol.lower-alpha ul li:first-child,
ol.upper-alpha ol li:first-child,
ol.upper-alpha ul li:first-child {
    margin-top: 5px;
}

/* Unordered Lists */
.arrow ul li,
ul.arrow li {
    background: url("../../images/list-arrow.png") no-repeat left 0.416em; /* 5px */
    padding-left: 11px;
}

.circle ul li,
ul.circle li {
    background: url("../../images/list-circle.png") no-repeat left 0.583em; /* 7px */
    padding-left: 11px;
}

.square ul li,
ul.square li {
    background: url("../../images/list-square.png") no-repeat left 0.583em; /* 7px */
    padding-left: 11px;
}

.check ul li,
ul.check li {
    background: url("../../images/list-check.png") no-repeat left 0.416em; /* 5px */
    padding-left: 17px;
}

/* Ordered Lists */
.decimal ol,
ol.decimal {
    list-style: decimal;
}

.upper-roman ol,
ol.upper-roman {
    list-style: upper-roman;
}

.lower-alpha ol,
ol.lower-alpha {
    list-style: lower-alpha;
}

.upper-alpha ol,
ol.upper-alpha {
    list-style: upper-alpha;
}
