

/* !Content: Blog */
.mod_newsmenu h5,
.mod_navigation h5 {
    margin-bottom: 0;
}

#comments,
#respond {
    padding-top: 40px;
}

#respond {
    border-top: 1px solid #e5e5e5;
    .content-accordionSingle {
        margin: 5px 0 15px 0;
        width: 72.5%;
        ul {
            color: #999;
            li {
                margin-left: 3px;
            }
            strong {
                color: $isotope_colour;
            }
        }
    }
}

.mod_newsarchive {
    .entry,
    .mod_newsreader .entry {
        border-top: 1px solid #e5e5e5;
        margin-bottom: 10px;
        padding-top: 30px;
    }
}

.mod_newsarchive .entry:first-child,
.mod_newsarchive .pagination + .entry,
.mod_newsreader .entry,
.mod_newsreader .pagination + .entry {
    border: none;
    padding-top: 0;
}
.mod_newsarchive .entry:first-child,
.mod_newsreader .entry {
    padding-top: 5px;
}

.mod_newsarchive .entry.sticky {
    border: 3px solid #e5e5e5;
    padding: 15px;
    margin-bottom: 40px;
}

.mod_newsarchive .entry.sticky+.entry {
    border-top: none;
    padding-top: 0;
}

.entry-date {
    float: left;
    margin: 0 20px 10px 0;
    text-align: center;
    width: 44px;
}

.entry-day {
    background-color: #f2f2f2;
    border-radius: 3px 3px 0 0;
    color: #666;
    font-size: 20px; /* 1.666em = 20px */
    font-weight: bold;
    height: 40px;
    line-height: 40px; /* vertical align */
}

.entry-month {
    background-color: #666;
    border-radius: 0 0 3px 3px;
    color: #fff;
    font-weight: bold;
    height: 25px;
    line-height: 25px; /* vertical align */
    text-transform: uppercase;
}

.entry-year {
    color: #ccc;
    height: 25px;
    line-height: 25px; /* vertical align */
}

.entry-body {
    overflow: hidden;
}

.entry-title {
    margin-bottom: 5px;
}

.entry-title {
    border: none;
    padding: 0;
}

.entry-title a {
    color: #333;
}

/* Blog: Meta */
#blog .left-margin {
    margin-left: 64px;
}
.mod_newsarchive,
.mod_newsreader {
    .entry-meta {
        margin-bottom: 15px;
        margin-top: 0;
        padding-left: 0;
        li {
            background: no-repeat left center;
            border-right: 1px solid #ccc;
            display: inline;
            margin-right: 12px;
            padding-right: 12px;
            &:last-child {
                border: none;
                margin-right: 0;
                padding-right: 0;
            }
        }
        .author {
            background-image: url("../../images/author.png");
            padding-left: 21px;
        }
        .category {
            background-image: url("../../images/folder.png");
            padding-left: 23px;
        }
        .comments {
            background-image: url("../../images/comment.png");
            padding-left: 21px;
        }
    }
    .entry-content {
        margin-bottom: 30px;
        .teaser {
            font-weight: bold;
        }
        a[href^="http://"]:after,
        a[href^="https://"]:after {
            content: " \f08e";
            font-family: FontAwesome;
        }
        a[href^="https://cdn.isotopeecommerce.org"]:after {
            content: "";
        }
        iframe {
            margin-bottom: 20px;
        }
        .release,
        ul {
            margin-bottom: 20px;
        }
        ul.github_release {
            li {
                border-bottom: 1px dotted #f2f2f2;
            }
            a {
                display: block;
            }
        }
    }
}

/* Blog: Share */
.mod_newsreader .share-wrap {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 15px 0 5px 0;
    h4 {
        float: left;
        margin-right: 20px;
    }
    .social-links {
        float: right;
    }
    h4,
    .social-links li {
        margin-bottom: 10px;
    }
}

/* Blog: Author */
.author-info {
    background-color: #f5f5f5;
    margin-bottom: 30px;
    padding: 20px 15px;
    position: relative;
    h4 {
        margin-bottom: 5px;
    }
    a.home {
        font-family: FontAwesome;
        font-size: 21px; /* 1.666em */
        &:before {
            content: "\f015";
        }
    }
}

.author-info,
.author-bio {
    overflow: hidden;
}

.author-image {
    float: left;
    margin: 0 20px 10px 0;
    a {
        display: block;
    }
    img {
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
        display: block;
        height: 70px;
        padding: 4px;
        width: 70px;
    }
}

/* Blog: Comment List */
ol.comment-list,
ol.children,
ul.children {
    list-style: none;
    margin: 0;
    padding: 0;
}

li.comment {
    .comment-wrap {
        border-top: 1px solid #e5e5e5;
        padding: 20px 0;
    }
    ol.children,
    ul.children {
        margin-left: 65px;
    }
}

/* Blog: Comment List Content */
ol.comment-list {
    .avatar-wrap {
        float: left;
        margin: 0 15px 0 0;
    }
    .avatar img {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
        display: block;
        padding: 3px;
    }
    .comment-details {
        overflow: hidden;
    }
    .comment-author {
        font-weight: bold;
        a {
            color: #333;
            &:hover {
                color: $isotope_colour;
            }
        }
        &.post-author {
            color: $isotope_colour;
            font-style: italic;
        }
    }
    .comment-meta {
        color: #888;
        margin-bottom: 15px;
    }
    .comment-content {
        .quote {
            border-left: 2px solid #dbdbdb;
            margin-bottom: 20px;
            padding-left: 10px;
            p {
                font-weight: 700;
                margin-bottom: 5px;
            }
        }
    }
}

/* Blog & Community - Startseite */
.mod_newslist,
.mod_rssReader {
    .layout_simple,
    .rss_items_only {
        margin-bottom: 8px;
        time,
        .time {
            display: block;
        }
        a {
            display: block;
            overflow-x: hidden;
            overflow-y: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 200px;
        }
    }
}
