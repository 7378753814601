/* !Content: Infobox */
.infobox {
    border-left: 4px solid $isotope_colour;
    margin-bottom: 20px;
    .box {
        display: inline-block;
        float: right;
        text-align: center;
        .button {
            /* font-size: 1em; */
            width: 100px;
        }
    }
    h2 {
        border: none;
        margin-bottom: 5px;
        padding: 0;
    }
    div.with-button {
        display: inline;
    }
    .button {
        float: right;
        margin: 0 0 5px 20px;
    }
    .mobile-button {
        display: none;
    }
    *:last-child {
        margin-bottom: 0;
    }
}

.infobox-inner {
    background-color: #f5f5f5;
    border: 1px solid #dbdbdb;
    border-left: none;
    margin-bottom: 20px;
    padding: 15px 20px;
}
