

/* !Content: Team */
.team-member {
    text-align: center;
    figure {
        display: block;
        margin-bottom: 10px;
    }
    img {
        display: block;
        margin: 0 auto 15px auto;
        max-width: 100%;
        width: 100%;
    }
    .content {
        margin-bottom: 30px;
    }
    .name {
        margin: 0;
    }
    .country {
        display: block;
        margin-bottom: 2px;
    }
    .job-title {
        display: block;
        margin-bottom: 15px;
        color: #888;
    }
    .social-links {
        border-top: 1px solid #e5e5e5;
        margin-bottom: 15px;
        padding-top: 10px;
        text-align: center;
        li {
            margin-bottom: 10px;
        }
    }
}
