

/* !Content: Accordion */
.mod_faqlist.content-accordion {
    font-size: 14px;
}
.ui-accordion,
.content-accordion {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 1.5em; /* 18px */
    .ui-helper-reset {
        line-height: 1.5em; /* 18px */
    }
    .ui-widget-content {
        color: #333;
    }
    .ui-accordion-header {
        background: none;
        background-color: #f5f5f5;
        border: 1px solid #dbdbdb;
        border-radius: 0;
        color: #333;
        cursor: pointer;
        display: block;
        font-weight: bold;
        margin: 0;
        padding: 10px 10px 10px 40px;
        position: relative; /* sets the initial position for the icon */
        &:hover,
        &.ui-state-active {
            color: $isotope_colour;
            background-color: #fafafa;
        }
        .ui-accordion-header-icon {
            position: absolute;
            top: 9px;
            left: 10px;
            background: url("../../images/toggle-plus.png") no-repeat left top;
            width: 20px;
            height: 20px;
            margin: 0;
        }
        &.ui-state-active .ui-accordion-header-icon {
            background-image: url("../../images/toggle-minus.png");
        }
    }
    > div:first-child > .ui-accordion-header {
        border-top: 1px solid #dbdbdb;
    }
    &[data-disabled="true"] .ui-accordion-header,
    &[data-disabled="true"] .ui-accordion-header:hover {
        color: #888;
        cursor: default;
    }
    .ui-accordion-content {
        background-color: #fff;
        border: 1px solid #dbdbdb;
        border-top: none;
        border-radius: 0;
        overflow: hidden;
        padding: 15px 10px;
        > *:last-child {
            margin-bottom: 0;
        }
    }
}
.isotope_demo {
    .ui-accordion-content {
        padding: 0 10px;
        text-align: center;
    }
    .content-text p {
        margin-bottom: 0;
    }
}

section.content-accordion {
    margin-bottom: -1px;
}

.toggles-wrap {
    margin-bottom: 20px;
    > div {
        margin-top: -1px;
        &:first-child {
            margin-top: 0;
        }
    }
}

.demo #content figure img {
    border: 1px solid #ebebeb;
    margin-bottom: 25px;
}
