
/* !Layout: Containers */
#wrapper {
    border-top: 4px solid $isotope_colour;
    overflow: hidden; /* removes empty space at the right of mobile devices display */
}

.boxed #wrapper {
    background-color: #fff;
    box-shadow: 0 0 8px rgba(0,0,0,0.11);
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
}

#header {
    border-color: $isotope_colour;
}

.container {
    margin: 0 auto;
    max-width: 100%;
    width: 95%;
}

#main {
    margin-bottom: 0;
}


/* !Media: Smartphone-Stylesheet */
@media (max-width:768px) {
    /* !Layout: Containers */
    #wrapper {
        border-top: 40px solid $isotope_colour;
    }

    #wrapper #page-title {
        padding: 10px 0;
        h1 {
            font-size: 26px;
        }
    }
}


@media (min-width:980px) {
    .boxed #wrapper {
        width: 1020px;
    }

    .container {
        width: 940px;
    }
}
